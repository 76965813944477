body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4 !important;
}

.App-logo {
  max-width: 62px;
}

.ant-layout-sider-zero-width-trigger {
  position: fixed !important;
  left: 0;
  display: block;
  z-index: 999 !important;
}

.ant-layout-sider {
  height: 100vh;
}

.ant-layout-content {
  margin: 0 20px;
  padding: 20px;
  background-color: #ffffff;
}


.ant-table {
  display: block;
}

@media (max-width: 600px) {
  .ant-table-thead {
    display: none;
  }

  .ant-table-thead>tr th:first-of-type,
  .ant-table-tbody>tr th:first-of-type,
  .ant-table-thead>tr td:first-of-type,
  .ant-table-tbody>tr td:first-of-type {
    padding-top: 1rem;
  }

  .ant-table-thead>tr th:last-of-type,
  .ant-table-tbody>tr th:last-of-type,
  .ant-table-thead>tr td:last-of-type,
  .ant-table-tbody>tr td:last-of-type {
    padding-bottom: 1rem;
  }

  .ant-table-thead>tr >th,
  .ant-table-tbody>tr >th,
  .ant-table-thead>tr >td,
  .ant-table-tbody>tr >td {
    display: block;
    width: auto !important;
    border: none;
    padding: 0 1rem;
    font-size: 1.1rem;
  }

  .ant-table-thead>tr >th:last-child,
  .ant-table-tbody>tr >th:last-child,
  .ant-table-thead>tr >td:last-child,
  .ant-table-tbody>tr >td:last-child {
    border-bottom: 1px solid #eee;
  }
}