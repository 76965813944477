@import '~antd/dist/antd.css';
@import '~video-react/dist/video-react.css';

a {
  color: #11618c;
}

a:hover {
  color: #949494;
}

.ant-avatar {
  cursor: pointer;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #949494;
}
  
.ant-layout-sider-zero-width-trigger {
  top: 11px !important;
  transition: left .2s ease;
}

.ant-btn-primary {
  background-color: #11618c;
  border-color: #11618c;
}

.ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus {
  background-color: #949494;
  border-color: #949494;
}

.ant-layout-header {
  position: fixed;
  z-index: 3;
  background: #fff;
  padding: 0; 
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #eee;
}

.ant-layout-content {
  margin-top: 80px !important;
}

.chat_wrapper {
  max-height: calc(100vh - 430px);
  overflow: auto;
}

.ant-tabs-tabpane .chat_wrapper {
  max-height: calc(100vh - 500px);
  overflow: auto;
}

.chat_wrapper .ant-list-item {
  display: block;
  border: 0 !important;
}

.chat_wrapper .ant-comment {
  padding-left: 30px;
}

.chat_wrapper .ant-list-item-meta-description {
  font-size: 12px;
  line-height: 12px;
}

.chat_wrapper .ant-list-item-meta-title {
  margin-bottom: 0;
}

.chat_wrapper .ant-comment-inner {
  padding: 10px 0;
}

.chat_wrapper .ant-list-item-meta-avatar {
  margin-right: 10px;
}

.chat_wrapper .ant-avatar, .ant-comment-avatar .ant-avatar, .ant-comment-avatar img {
  width: 48px !important;
  height: 48px !important;
}

.chat_wrapper .ant-comment-content {
  font-size: 16px;
}

.reportTable {
  width: 100%;
}

.reportTable thead {
  background: #ddd;
}

.reportTable thead td{
  font-weight: bold;
}

.reportTable tr:nth-child(even) {
  background: #f3f3f3;
}

.reportTable td {
  padding: 8px;
}

.ant-collapse, .ant-collapse-item {
  border: 0 !important;
}

.ant-collapse-header {
  background: #fff;
  font-weight: bold;
}

.danger {
  color: red;
}

.ant-upload-btn {
  height: 158px !important;
}

.ant-upload-list-item {
  display: none;
}

.documentList {
  display: flex;
  flex-wrap: wrap;
}

.documentList .ant-card-bordered {
  border-radius: 5px;
  padding: 5px;
  position: relative;
  width: 173px;
  height: 173px;
  margin-right: 10px;
}

.documentList .ant-card-bordered .ant-card-body {
  padding: 0;
}

.documentList .ant-card-bordered .ant-card-actions {
  visibility: hidden;
  position: absolute;
  bottom: -10px;
  transition: 0.3s ease-in-out;
  width: calc(100% - 10px);
}

.documentList .ant-card-bordered:hover .ant-card-actions {
  visibility: visible;
  bottom: 0px;
  background: rgba(255,255,255,.8);
}

.documentList .ant-card-bordered .ant-card-cover img, .documentList .ant-card-bordered .ant-card-cover .video-react {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.documentList .ant-card-bordered .ant-card-cover {
  min-width: 160px;
  min-height: 133px;
  display: flex;
  align-items: center;
}

.ant-modal-close {
  background: #fff !important;
}

.timeNavWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

.timeNavWrapper span {
  padding-left: 15px;
}

.ant-btn.ant-btn-link {
  color: #11618c;
}

.ant-page-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.folderWrapper > .ant-col:first-child {
  padding-right: 15px;
  padding-top: 65px;
}

.folderWrapper a {
  word-break: break-all;
}

.ant-list-item.active {
  background-color: #eeeeee;
}

.ant-list-item.active .ant-list-item-meta-title a {
  color: #11618c;
}

.ant-comment-content-detail img {
  cursor: pointer;
}

.addDocument {
  background: #eee;
}

.analysisHeader {
  display: flex;
  justify-content: space-between;
}

.analysisStats {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  margin: 15px 0;
  padding: 15px 0;
}

.analysisList {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.analysisStats > div {
  align-items: flex-end;
  display: flex;
}

.ant-statistic-title span {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 3px;
}

.folderMoreButton {
  padding: 0;
}

label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked, label.ant-checkbox-wrapper {
  line-height: 14px;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  overflow: hidden;
}

.ant-modal {
  top: 50px !important;
}

.ant-layout-header {
  width: 100%;
}

@media (min-width: 992px) {
  .ant-layout {
    padding-left: 100px;
  }
  .ant-layout-header {
    width: calc(100% - 200px);
  }
}

@media (max-width: 992px) {
  .ant-layout-sider {
    z-index: 999;
  }
  .ant-layout-sider:not(.ant-layout-sider-collapsed) .ant-layout-sider-zero-width-trigger {
    left: 200px;
  }
}
